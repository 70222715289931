import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const clienteRoutes = [
  {
    path: '/material/perfil',
    element: <AppTable />,
  },
  {
    path: '/material/perfil/edit/:id',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/perfil/consultar/:id',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/perfil/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  },

];

export default clienteRoutes;
