import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const revendedorRoutes = [
  {
    path: '/material/revendedor',
    element: <AppTable />,
  },
  {
    path: '/material/revendedor/edit/:codigoRevendedor',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/revendedor/consultar/:codigoRevendedor',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/revendedor/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  },

];

export default revendedorRoutes;
