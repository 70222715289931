import React, { createContext, useEffect, useReducer, useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import useAuth from 'app/hooks/useAuth';
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    matrizDisponivel: null,
    matrizSelecionada: null,
    matrizFilialSelecionada: null
}



const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('access', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('access')
        delete axios.defaults.headers.common.Authorization
    }
}

const setMatrizFilialSelecionada = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('matrizSelecionada', accessToken)
    } else {
        localStorage.removeItem('matrizSelecionada')
    }
}



const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, matrizDisponivel, matrizSelecionada } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                matrizDisponivel,
                matrizSelecionada
            }
        }
        case 'LOGIN': {
            const { user, matrizDisponivel, matrizSelecionada } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                matrizDisponivel,
                matrizSelecionada
            }
        }

        case 'setarMatriz': {
            const { user, matrizDisponivel, matrizSelecionada } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                matrizDisponivel,
                matrizSelecionada
            }
        }
        case 'refresh': {
            const { isAuthenticated, user, matrizDisponivel, matrizSelecionada } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                matrizDisponivel,
                matrizSelecionada
            }
        }



        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}



const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    setarMatriz: () => Promise.resolve(),
    refreshSession: () => Promise.resolve(),
    refreshToken: () => Promise.resolve()

})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { user, matrizDisponivel, matrizSelecionada } = useAuth();

    const login = async (email, password) => {
        var username = email

        const headers = { 'X-TENANT-ID': 'public' };
        const response = await axios.post('/authenticate/authenticate', {
            username,
            password,
        }, headers)
        const { token, user, matrizDisponivel } = response.data
        console.log(response.data)
        setSession(token)


        dispatch({
            type: 'LOGIN',
            payload: {
                isAuthenticated: true,
                user,
                matrizDisponivel
            },
        })
    }

    const setarMatriz = async (matrizSelecionada) => {
        console.log("MATRIZ");
        console.log(matrizSelecionada);
        const response = await axios.get(`/authenticate/refreshToken?schema=${matrizSelecionada.idMatrizFilial}`, {}, {
            headers: {
                'X-TENANT-ID': 'public',
            },
        });
        setMatrizFilialSelecionada(matrizSelecionada.idMatrizFilial)
        dispatch({
            type: 'setarMatriz',
            payload: {
                isAuthenticated: true,
                user: {
                    ...state.user,
                    matrizSelecionada,
                },
                matrizDisponivel: state.matrizDisponivel,
                matrizSelecionada
            },
        })
    }

    const register = async ( email,         
        password,
        nome,
        cpf,
        cep,
        rua,
        numero,
        esrtado,cidade,telefone) => {
        //register(values.email,  values.password, values.nome, values.cpf,values.cep,values.rua,values.numero,values.estado,values.cidade);

        const response = await axios.post('/authenticate/registrar', {
            email,         
            password,
            nome,
            cpf,
            cep,
            rua,
            numero,
            esrtado,cidade,telefone
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    const refreshSession = async (user, matrizDisponivel, matrizSelecionada) => {
        console.log("refreshSession");
        console.log(matrizSelecionada);
      //  setMatrizFilialSelecionada(matrizSelecionada.idMatrizFilial)
        dispatch({
            type: 'refresh',
            payload: {
                isAuthenticated: true,
                user: user,

            },
        })
    }
    const refreshToken = async () => {
        try {
            const accessToken = window.localStorage.getItem('access')
          
            if (accessToken) {
                console.log("----------------------------------------------------------------");
                console.log('REFRESH TOKEN' + accessToken)
                setSession(accessToken)
                const headers = { 'X-TENANT-ID': 'public' };
                const response = await axios.get(`/authenticate/refreshToken?schema=public`, headers);
                console.log('RET REFRESH TOKEN')
                const { token, user, matrizDisponivel, matrizSelecionada } = response.data
                setSession(token)
                console.log('----------------------------------------------------------------')


            } else {

                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        } catch (err) {
            console.error(err)
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('access')
                const matriz = window.localStorage.getItem('matrizSelecionada')
                console.log("----------------------------------------------------------------");
                console.log(accessToken)
                console.log(matriz)
                if (accessToken) {
                    console.log("----------------------------------------------------------------");
                    console.log('REFRESH TOKEN' + accessToken)
                    setSession(accessToken)
                    const headers = { 'X-TENANT-ID': 'public' };
                    const response = await axios.get(`/authenticate/refreshToken?schema=public`, headers);
                    console.log('RET REFRESH TOKEN')
                    const { token, user, matrizDisponivel, matrizSelecionada } = response.data
                    await refreshSession(user, matrizDisponivel, matrizSelecionada)
                    setSession(token)
                    //setMatrizFilialSelecionada(matrizSelecionada.idMatrizFilial)
                    console.log('----------------------------------------------------------------')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: user,
                            matrizDisponivel: null,
                            matrizSelecionada: null
                        },
                    })

                } else {

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                setarMatriz,
                logout,
                register,
                refreshSession,
                refreshToken
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
