import axios from 'axios';

const axiosInstance = axios.create({
   baseURL: 'https://rest.supertopbest.com/stb/',
   // baseURL: 'http://localhost:8080',
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access'); // Obtenha o token do armazenamento local
    if (accessToken) {
      // Se houver um token, configure o cabeçalho Authorization
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    Promise.reject((error.response && error.response.data) || 'Something went wrong!')
  }
);

export default axiosInstance;
