import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const IdentificacaoUsuario = Loadable(lazy(() => import('./identificacaoUsuario')));
const stbIdentificacaoUsuarioRoutes = [
  {
    path: '/material/stb/identificacaoUsuario',
    element: <IdentificacaoUsuario />,
  },
];

export default stbIdentificacaoUsuarioRoutes;
