import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const supervisorRoutes = [
  {
    path: '/material/supervisor',
    element: <AppTable />,
  },
  {
    path: '/material/supervisor/edit/:codigoSupervisor',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/supervisor/consultar/:codigoSupervisor',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/supervisor/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  }

];

export default supervisorRoutes;
