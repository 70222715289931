import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import React, { useState, useEffect } from 'react';
import { Snackbar, Icon, SnackbarContent } from "@material-ui/core";
import { CssBaseline } from '@mui/material';
import {
   
   Alert
} from "@mui/material";



const App = () => {
  const content = useRoutes(routes);
  const [open, setOpen] = React.useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
    //window.location.href ='/material/consultaTerminais'
  };

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mostraMsgSalvar = urlParams.get('mostraMsgSalvar');

    if (mostraMsgSalvar) {
      setIsSuccessSnackbarOpen(true)
    }


    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>
            <CssBaseline />
            {content}</AuthProvider>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={isOffline}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <SnackbarContent
              style={{ backgroundColor: 'red' }}
              message={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon color="error">close</Icon>
                  Você está offline
                </span>
              }
            />
          </Snackbar>

          <Snackbar
            open={isSuccessSnackbarOpen}
            autoHideDuration={2000}
            onClose={handleSuccessSnackbarClose}
            anchorOrigin={{
              vertical: 'top', // Define a âncora na parte superior
              horizontal: 'center', // Centraliza horizontalmente
            }}
          >
            <Alert severity="success" onClose={handleSuccessSnackbarClose}>
              Dados salvos com sucesso!
            </Alert>
          </Snackbar>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
