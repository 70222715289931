import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const clienteRoutes = [
  {
    path: '/material/notaFiscal',
    element: <AppTable />,
  }


];

export default clienteRoutes;
