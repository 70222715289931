import { Icon, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { topBarHeight } from 'app/utils/constant';

import React, { useState, useEffect } from 'react';
import axios from 'axios.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useAuth from 'app/hooks/useAuth';


const SearchContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 9,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: topBarHeight,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': {
    color: theme.palette.text.primary,
  },
}));

const SearchInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  fontSize: '1rem',
  paddingLeft: '20px',
  height: 'calc(100% - 5px)',
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': { color: theme.palette.text.primary },
}));

const MatxSearchBox = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { matrizSelecionada } = useAuth();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const { palette } = useTheme();
  const textColor = palette.text.primary;

  useEffect(() => {
    // Chamada inicial para carregar os resultados
    handleSearch('');
  }, []);

  const flattenMenus = (menus) => {
    let flattened = [];

    menus.forEach((item) => {
      flattened.push(item);

      if (item.children) {
        flattened = [...flattened, ...flattenMenus(item.children)];
      }
    });

    return flattened;
  };

  const handleSearch = async (value) => {
    /*
    setSearchValue(value);
    setLoading(true);

    try {
      const ComMatrizFilialModel = {
        logoMatrizFilial: matrizSelecionada.logoMatrizFilial,
        schema: matrizSelecionada.schema,
        idMatrizFilial: matrizSelecionada.idMatrizFilial,
        descricaoFilial: matrizSelecionada.descricaoFilial,
      };

      // Faz a solicitação à API /menu
      const response = await axios.post('/menu', JSON.stringify(ComMatrizFilialModel), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const results = response.data;
      
      // Filtrar todos os menus e submenu e transformá-los em uma lista plana
      const allMenus = flattenMenus(results);

      // Filtrar os resultados com base no valor de pesquisa e mapeá-los para suas respectivas informações
      const filteredResults = allMenus
        .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        .map((item) => ({ name: item.name, path: item.path }));

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Erro ao buscar resultados:', error);
    } finally {
      setLoading(false);
    }*/
  };
  

  return (
    <React.Fragment>
      {!open && (
        <IconButton onClick={toggle}>
          <Icon sx={{ color: textColor }}>search</Icon>
        </IconButton>
      )}

      {open && (
        <SearchContainer>
         
         <Autocomplete
         autoFocus
  options={searchResults}
  getOptionLabel={(option) => option.name}
  loading={loading}
  onInputChange={(_, value) => handleSearch(value)}
  style={{ width: '100%' }} // Define a largura para ocupar 100% da largura
  renderInput={(params) => (
    <TextField
      {...params}
      label="Buscar Tela..."
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? 'Loading...' : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )}
  onChange={(_, value) => {
    if (value && value.path) {
      window.location.href = value.path;
    }
  }}
/>


          <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: 'middle' }}>
            <Icon sx={{ color: textColor }}>close</Icon>
          </IconButton>
        </SearchContainer>
      )}
    </React.Fragment>
  );
};

export default MatxSearchBox;
