import React, { createContext, useEffect, useReducer, useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import useAuth from 'app/hooks/useAuth';

function getNavigations() {

  const navigations = [
    { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
    { name: 'tab', path: '/material/table', icon: 'dashboard' },
    { name: 'Pedido Mobile', path: '/material/pdv', icon: 'dashboard' },
    { name: 'Cliente', path: '/material/cliente', icon: 'dashboard' },
    { label: 'PAGES', type: 'label' },
    {
      name: 'Session/Auth',
      icon: 'security',
      children: [
        { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
        { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
        { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
        { name: 'Error', iconText: '404', path: '/session/404' },
      ],
    }
  ];

  return navigations;
}



export const navigations = getNavigations();
/*[
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'tab', path: '/material/table', icon: 'dashboard' },
  { name: 'Pedido Mobile', path: '/material/pedidoMobile', icon: 'dashboard' },
  { name: 'Cliente', path: '/material/cliente', icon: 'dashboard' },
  { label: 'PAGES', type: 'label' },
  {
    name: 'Session/Auth',
    icon: 'security',
    children: [
      { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
      { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
      { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      { name: 'Error', iconText: '404', path: '/session/404' },
    ],
  }

];*/
