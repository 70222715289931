import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PagamentoPix = Loadable(lazy(() => import('./pagamentoPix')));
const stbPagamentoPixRoutes = [
  {
    path: '/material/stb/pagamentoPixAnon',
    element: <PagamentoPix />,
  },
];

export default stbPagamentoPixRoutes;
