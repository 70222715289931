import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const dataTableRoutes = [
  {
    path: '/material/pedidoMobile',
    element: <AppTable />,
  },
  {
    path: '/material/pedidoMobile/edit/:idProduto',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/pedidoMobile/consultar/:idProduto',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/pedidoMobile/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  },

];

export default dataTableRoutes;
