import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ConfirmacaoPagamento = Loadable(lazy(() => import('./confirmacaoPagamento')));
const stbConfirmacaoPagamentoRoutes = [
  {
    path: '/material/stb/confirmacaoPagamento',
    element: <ConfirmacaoPagamento />,
  },
];

export default stbConfirmacaoPagamentoRoutes;
