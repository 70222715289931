import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./AppTable')));

const dataTableRoutes= [
  {
    path: '/material/table',
    element: <AppTable />,
  }
];

export default dataTableRoutes;
