import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const OpcoesCompra = Loadable(lazy(() => import('./opcoesCompra')));
const stbOpcoesCompraRoutes = [
  {
    path: '/material/stb/opcoesCompra',
    element: <OpcoesCompra />,
  },
];

export default stbOpcoesCompraRoutes;
