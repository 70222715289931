import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Compra = Loadable(lazy(() => import('./compra')));
const stbComprasRoutes = [
  {
    path: '/material/stb/compra/:opcaoProduto',
    element: <Compra />,
  },
  {
    path: '/material/stb/compra/:opcaoProduto/:codigoVendedor',
    element: <Compra />,
  },
];

export default stbComprasRoutes;
