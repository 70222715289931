import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const clienteRoutes = [
  {
    path: '/material/cliente',
    element: <AppTable />,
  },
  {
    path: '/material/cliente/edit/:codigoPessoa',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/cliente/consultar/:codigoPessoa',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/cliente/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  },

];

export default clienteRoutes;
