import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const IdentificacaoEndereco = Loadable(lazy(() => import('./identificacaoEndereco')));
const stbIdentificacaoEnderecoRoutes = [
  {
    path: '/material/stb/identificacaoEndereco',
    element: <IdentificacaoEndereco />,
  },
];

export default stbIdentificacaoEnderecoRoutes;
