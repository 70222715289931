import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const RevendedoresList = Loadable(lazy(() => import('./revendedoresList/index')));
const vendedorRoutes = [
  {
    path: '/material/vendedor',
    element: <AppTable />,
  },
  {
    path: '/material/vendedor/edit/:codigoVendedor/revendedores',
    element: <RevendedoresList />,
  },
  {
    path: '/material/vendedor/edit/:codigoVendedor',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/vendedor/consultar/:codigoVendedor',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/vendedor/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  },

];

export default vendedorRoutes;
