import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppTable = Loadable(lazy(() => import('./list/AppTable')));
const Edit = Loadable(lazy(() => import('./edit/edit')));
const Extracoes = Loadable(lazy(() => import('./edit/extracoes')));
const Boloes = Loadable(lazy(() => import('./edit/boloes')));
const Descarga = Loadable(lazy(() => import('./edit/descarga')));

const BaseRoutes = [
  {
    path: '/material/base',
    element: <AppTable />,
  },
  {
    path: '/material/base/edit/:codigoBase',
    element: <Edit consultar={false} editar={true} novo={false} />,
  },
  {
    path: '/material/base/consultar/:codigoBase',
    element: <Edit consultar={true} editar={false} novo={false} />,
  },
  {
    path: '/material/base/edit/:codigoBase/extracoes',
    element: <Extracoes />,
  },
  {
    path: '/material/base/edit/:codigoBase/descarga',
    element: <Descarga />,
  },
  {
    path: '/material/base/edit/:codigoBase/boloes',
    element: <Boloes />,
  },
  {
    path: '/material/base/novo',
    element: <Edit consultar={false} editar={false} novo={true} />,
  }

];

export default BaseRoutes;
