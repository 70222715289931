import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import dataTableRoutes from 'app/views/data-table/dataTableRoutes';
import pedidoMobileRoutes from 'app/views/pedidoMobile/dataTableRoutes';
import clienteRoutes from 'app/views/com-cliente/clienteRoutes';
import notaRoutes from 'app/views/nfe/notaFiscalRoutes';
import produtoRoutes from 'app/views/produto/notaFiscalRoutes';
import notaEntradaRoutes from 'app/views/notaEntrada/notaFiscalRoutes';
import consultaTerminais from 'app/views/consulta-terminais/consultaTerminalRoutes';
import menuUsuario from 'app/views/usuarios/menuUsuarioRoutes';
import menuPerfil from 'app/views/perfil/PerfilRoutes';
import Empresa from 'app/views/empresa/EmpresaRoutes';
import revendedorRoutes from 'app/views/revendedor/revendedorRoutes';
import vendedorRoutes from 'app/views/vendedor/vendedorRoutes';
import equipamentoRoutes from 'app/views/equipamento/equipamentoRoutes';
import lancamentoPreCaixa from 'app/views/lancamentoPreCaixa/lancamentoPreCaixaRoutes';
import supervisorRoutes from 'app/views/supervisor/supervisorRoutes';
import BaseRoutes from 'app/views/base/baseRoutes';

import stbComprasRoutes from './STB/view/compra/stbCompraRoutes';
import stbIdentificacaoUsuarioRoutes from './STB/view/identificacaoUsuario/stbIdentificacaoUsuarioRoutes';
import stbIdentificacaoEnderecoRoutes from './STB/view/identificacaoEndereco/stbIdentificacaoEnderecoRoutes';
import stbPagamentoPixRoutes from './STB/view/pagamentoPix/stbPagamentoPixRoutes';
import stbOpcoesCompraRoutes from './STB/view/opcoesCompra/stbOpcoesCompraRoutes';
import stbMeusBilhetesRoutes from './STB/view/meusBilhetes/stbMeusBilhetesRoutes';
import stbConfirmacaoPagamentoRoutes from './STB/view/confirmacaoPagamento/stbConfirmacaoPagamentoRoutes';
import stbLoginRoutes from './STB/view/login/stbLoginRoutes';
import stbPixSemPagador from './STB/view/compraSpPIX/stbPagamentoPixRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...dataTableRoutes,
      ...pedidoMobileRoutes,
      ...notaRoutes,
      ...clienteRoutes,
      ...produtoRoutes,
      ...notaEntradaRoutes,
      ...consultaTerminais,
      ...menuUsuario,
      ...menuPerfil,
      ...Empresa,
      ...revendedorRoutes,
      ...vendedorRoutes,
      ...equipamentoRoutes,
      ...lancamentoPreCaixa,
      ...supervisorRoutes,
      ...BaseRoutes,

      //STB
      ...stbMeusBilhetesRoutes,
      ...stbIdentificacaoUsuarioRoutes,
      ...stbIdentificacaoEnderecoRoutes,
      ...stbPagamentoPixRoutes,
    ],
  },
  ...sessionRoutes,
  ...stbComprasRoutes,      
      ...stbOpcoesCompraRoutes,      
      ...stbConfirmacaoPagamentoRoutes,
     ...stbPixSemPagador,
  { path: '/', element: <Navigate to="/material/stb/opcoesCompra" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
