import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Login = Loadable(lazy(() => import('./login')));
const stbLoginRoutes = [
  {
    path: '/material/stb/login',
    element: <Login />,
  },
];

export default stbLoginRoutes;
