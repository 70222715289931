import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const MeusBilhetes = Loadable(lazy(() => import('./meusBilhetes')));
const ConsultaBilhete = Loadable(lazy(() => import('./consultaBilhete')));
const stbMeusBilhetesRoutes = [
  {
    path: '/material/stb/meusBilhetes',
    element: <MeusBilhetes />,
  },
  {
    path: '/material/stb/meusBilhetes/consultar/:numeroPedido/:idUsuario',
    element: <ConsultaBilhete />,
  },
];

export default stbMeusBilhetesRoutes;
